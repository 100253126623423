Date.prototype.valid = function() {
    return isFinite(this);
}

$(document).on('turbolinks:load', function() {
    loadAllTableText();
    addEventListeners();
    if($('.tablesort th.tablesort-default').length) {
        $('.tablesort th.tablesort-default').trigger('click');
    }
});

function addEventListeners() {
    $('body').on('click', '.tablesort th', function () {
        // Prüfen, ob die Sortierung für dieses <th> deaktiviert ist
        if ($(this).hasClass('tablesort-disabled') || $(this).attr('data-tablesort-disabled') === "true") {
            return; // Sortierung wird nicht ausgeführt
        }

        // Schließt Dropdowns, wenn eine Sortierung ausgeführt wird
        $('.dropdown-toggle').dropdown('hide');
        tableSort(this);
    });

    $('.tablesearch-input').on('keyup', function () {
        tableSearch(this);
    });
}

const SORT_TYPES = {
    INT: "int",
    FLOAT: "float",
    STRING: "string",
    DATE: "date",
};

/////////////////////////////
// Gets the sortable value //
/////////////////////////////
function getSortableValue(cell, type) {
    let value = $(cell).attr("data-tablesort-value");

    if (value === undefined) {
        value = $(cell).text().trim();
    }

    if (type === SORT_TYPES.INT || type === SORT_TYPES.FLOAT) {
        let floatValue = parseFloat(value.replace(' ', '').replace(',', '.'));
        return isNaN(floatValue) ? 0 : parseFloat(floatValue.toFixed(10)); // force precision
    }

    if (type === SORT_TYPES.DATE) {
        return new Date(value).getTime() || 0;
    }

    return value.toUpperCase(); // Standardmäßig String-Sortierung
}
/////////////////////////////
// Determine the type       //
/////////////////////////////
function determineType(thClicked) {
    var table = $(thClicked).closest('.tablesort');
    var columnIndex = getCellIndex(thClicked);
    var rows = $(table).find('tbody tr');
    var isString = false;
    var isFloat = false;

    for (var count = 0; count < rows.length; count++) {
        var cells = $(rows[count]).find('td');
        var cellValue = getSortableValue(cells[columnIndex], SORT_TYPES.STRING);

        if ($.isNumeric(cellValue) == false) {
            isString = true;
        } else if (cellValue.includes('.')) {
            isFloat = true;
        }
    }

    if (isString) return SORT_TYPES.STRING;
    if (isFloat) return SORT_TYPES.FLOAT;
    return SORT_TYPES.INT;
}

// Sortierfunktion für generische Werte (INT/FLOAT/STRING/DATE)
function sortRowsGeneric(rows, columnIndex, type, ascending = true) {
    return rows.sort(function (a, b) {
        var cellsA = $(a).find('td');
        var cellsB = $(b).find('td');
        var valA = getSortableValue(cellsA[columnIndex], type);
        var valB = getSortableValue(cellsB[columnIndex], type);

        if (valA < valB) return ascending ? -1 : 1;
        if (valA > valB) return ascending ? 1 : -1;
        return 0;
    });
}
/////////////////////////////
// Sorts the table         //
/////////////////////////////
function tableSort(thClicked) {
    var table = $(thClicked).closest('.tablesort');
    var columnIndex = getCellIndex(thClicked);
    var rows = $(table).find('tbody tr');
    var sortType;

    if ($(thClicked).data('tablesort-type')) {
        sortType = $(thClicked).attr('data-tablesort-type');
    } else {
        sortType = determineType(thClicked);
    }

    if ($(thClicked).hasClass('tablesort-asc')) {
        $(table).find('thead th').removeClass('tablesort-asc').removeClass('tablesort-desc');
        $(thClicked).addClass('tablesort-desc');
        rows = sortRowsGeneric(rows, columnIndex, sortType, false);
    } else {
        $(table).find('thead th').removeClass('tablesort-asc').removeClass('tablesort-desc');
        $(thClicked).addClass('tablesort-asc');
        rows = sortRowsGeneric(rows, columnIndex, sortType, true);
    }

    $(table).find('tbody').html(rows);
}
/////////////////////////////
// Gets the cell index     //
/////////////////////////////
function getCellIndex(th) {
    return $(th).closest('table').find('tr').find(th).index();
}


// Table Search

/////////////////////////////
// Load all the table text //
/////////////////////////////
function loadAllTableText() {
    var tablesearchTables = $('.tablesearch-table');

    for (var count = 0; count < tablesearchTables.length; count++)
        loadTableText(tablesearchTables[count]);
}

/////////////////////////
// Load the table text //
/////////////////////////
function loadTableText(table) {
    var cells = $(table).find('tbody td.tablesearch-source');
    if(cells.length === 0) {
        // fallback to all cells
        cells = $(table).find('tbody td');
    }

    for (var count = 0; count < cells.length; count++) {
        var cell = cells[count];
        var upperCaseText = $(cell).text().trim().toUpperCase();
        $(cell).attr('data-tablesearch-text', upperCaseText);
    }
}

//////////////////////
// Search the table //
//////////////////////
function tableSearch(input) {
    var text = $(input).val().toUpperCase();
    var table = $(input).attr('data-tablesearch-table');

    if (text == '' || text.length == 0) {
        $(table).find('tbody tr').removeClass('d-none');
        return;
    }

    $(table).find('tbody tr').addClass('d-none');
    $(table).find('tbody td[data-tablesearch-text*="' + text + '"]').closest('tr').removeClass('d-none');
}
